import React, { Fragment, useRef, useState } from "react";
import { BiScreenshot, BiShapeSquare } from "react-icons/bi";
import { FiPlay, FiArrowRight, FiZap, FiFilm, FiSliders, FiFileText, FiPlayCircle } from "react-icons/fi";
import { HiCheckCircle } from "react-icons/hi";
import { RoughNotation } from "react-rough-notation";
import { SquareDots, ArrowDrawn } from "../components/icons";
import HoverVideoPlayer from 'react-hover-video-player';
import { Dialog, Transition } from '@headlessui/react'

import Layout from "../components/layout";
import heroBg from "../images/hero-bg2.jpg";
import heroBg3 from "../images/hero-bg3.jpg";
import SEO from "../components/seo";
import { graphql, useStaticQuery } from "gatsby";

export const query = graphql`
query MockupVideoTemplates {
  templates: allGraphCmsTemplate(limit: 6, sort: {order: DESC, fields: createdAt}) {
    nodes {
      poster {
        localFile {
          childImageSharp {
            gatsbyImageData(height: 600, quality: 70)
          }
        }
      }
      videoPreview
      title
    }
  }
}
`;

const StockVideoDevicePage = () => {
  const templates = useStaticQuery(query).templates.nodes;
  return (
    <>
    <Layout>
      <SEO
        keywords={[`mockup`, `video`, `maker`, `editor`, `online`, `stock`, `app`, `device`, `phone`, `tablet`, `desktop`, `screen`, `replacement`, `iphone`, `macbook`, `laptop`, `samsung`]}
        title="Stock Video Device Mockup Maker"
      />

      <section className="bg-gradient-to-l from-blue-500 to-blue-700 relative bg-cover" style={{ backgroundImage: `url('${heroBg}')`, backgroundPosition: '100%' }}>

        <div className="container mx-auto pt-28 relative z-10">
          <div className="mx-auto w-full px-6 md:px-0 md:w-4/5 lg:w-2/3">
            <h1 className="text-center text-white font-semibold text-3xl md:text-5xl leading-tight mb-2">Stock Video Mockup Maker</h1>
            <h2 className="w-full px-0 md:px-6 md:w-3/4 mx-auto text-center text-base md:text-xl text-indigo-300">Upload your media and replace the screen with your content. Preview &amp; render your video to 4K or HD in minutes.</h2>

            <div className="relative mb-12">
              <div className="hidden mt-7">
                <div className="flex flex-row flex-wrap justify-center">
                  <button className="sm:mr-4 flex items-center w-full justify-center sm:w-auto md:inline-flex bg-white bg-opacity-15 text-white border border-white border-opacity-25 h-12 px-5 focus:outline-none hover:bg-opacity-100 hover:text-blue-500 rounded transition duration-200"><FiPlay className="mr-1" />Watch Overview</button>
                  <button className="mt-2 sm:mt-0 flex w-full items-center justify-center sm:w-auto md:inline-flex text-white transition duration-150 bg-indigo-700 h-12 px-5 focus:outline-none hover:bg-indigo-600 rounded border border-indigo-600">Create Mockup Now</button>
                </div>

                <div className="absolute text-xs top-0 z-10 text-indigo-200 text-opacity-50 transform -scale-x-1 rotate-12" style={{ right: '4px', top: '-5px' }}><ArrowDrawn className="h-10 text-indigo-200 fill-current opacity-50" /></div>
                <div className="absolute handwritten text-base right-0 top-0 z-10 text-indigo-200 text-opacity-50 transform rotate-12" style={{ right: 62, top: '-18px' }}>Try without signing up</div>
              </div>
            </div>
          </div>

          <div className="mx-auto w-full px-6 md:px-0 md:w-4/5">
            <div className="flex flex-row items-center h-8 px-2 bg-gray-300 bg-opacity-15 rounded-t-md border border-white border-opacity-25">
              <div className="h-3 w-3 rounded-full bg-red-500 mr-1.5"></div>
              <div className="h-3 w-3 rounded-full bg-orange-500 mr-1.5"></div>
              <div className="h-3 w-3 rounded-full bg-green-500"></div>
            </div>
            <div className="rounded-b-md flex items-center justify-center cursor-pointer relative" onClick={() => setOpen(true)}>
              <div className="absolute z-10">
                <div className="h-28 w-28 flex items-center justify-center rounded-full bg-gray-900 bg-opacity-85"><FiPlay className="text-indigo-100" style={{fontSize: '2.5rem'}}/></div>
                </div>
              <video className="w-full h-full rounded-b-md" autoPlay={true} controls={false} muted={true} loop={true} playsInline={true} poster={'/videos/walkthrough-ss.jpg'} >
                <source src={'/videos/walkthrough.mp4'} type="video/mp4" />
              </video>
            </div>
          </div>
        </div>

        {/* <div className="bg-svg-shape-1"></div> */}
        <div className="relative page-header-wave">
          <svg style={{minHeight: '27vw'}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 290" preserveAspectRatio="xMinYMid slice">
            <path fill="#f7fafc" fillOpacity="1" d="M0,288L1440,160L1440,320L0,320Z"></path>
          </svg>
        </div>
      </section>

      <section className="bg-gray-100 lg:pt-32 pt-16">
        <div className="container mx-auto pt-10 md:pt-24 lg:pt-16 xl:pt-36 pb-18 md:pb-28 px-6">
          <div className="flex flex-row flex-wrap -mx-8">
            <div className="relative w-full lg:w-1/2 px-8 mt-12 lg:mt-0">
              <video className="relative z-20 rounded shadow-xl mb-4" muted={true} autoPlay={true} loop={true} playsInline={true}>
                <source src="/videos/site-compilation.mp4" type="video/mp4" />
              </video>
              <div className="absolute transform scale-50 z-10" style={{ top: -71.5, left: -85 }}>
                <SquareDots className="text-indigo-600" />
              </div>
              <div className="hidden md:block absolute border rounded border-indigo-600 border-opacity-50" style={{ top: 35, left: 0, height: 300, width: '88%' }}></div>
            </div>
            <div className="w-full lg:w-1/2 px-8">
              <div className="flex flex-wrap pt-0 md:pt-4">
                <div>
                  <h3 className="text-2xl md:text-4xl gradient-text leading-tight text-gray-800 font-semibold mb-5">Easily place your image into professional 4K stock videos</h3>
                  <ul className="space-y-1.5 text-lg text-gray-700 leading-relaxed text-opacity-75">
                    <li><HiCheckCircle className="inline-flex -mt-0.5 mr-1.5 text-green-500 text-2xl" /><span className="font-bold text-indigo-900">Real-time playback</span> in your browser</li>
                    <li><HiCheckCircle className="inline-flex -mt-0.5 mr-1.5 text-green-500 text-2xl" /><span className="font-bold text-indigo-900">Precise object tracking</span> for realistic results</li>
                    <li><HiCheckCircle className="inline-flex -mt-0.5 mr-1.5 text-green-500 text-2xl" />Full control over <span className="font-bold text-indigo-900">reflections &amp; colour settings</span></li>
                    <li><HiCheckCircle className="inline-flex -mt-0.5 mr-1.5 text-green-500 text-2xl" />Royalty-free <span className="font-bold text-indigo-900">commercial license</span></li>
                    <li><HiCheckCircle className="inline-flex -mt-0.5 mr-1.5 text-green-500 text-2xl" />Export to high-quality <span className="font-bold text-indigo-900">4K</span> or <span className="font-bold text-indigo-900">Full HD</span></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-cover" style={{ backgroundImage: `url('${heroBg3}')`, backgroundPosition: '100%' }}>
        <div className="container mx-auto pt-16 md:pt-20 pb-6 px-6">
          <div className="w-full mx-auto md:text-center mb-8 md:mb-12">
            <h3 className="text-2xl md:text-4xl leading-tight text-white font-semibold">High quality video library.<br />Royalty-free &amp; ready for commercial use.</h3>
          </div>

          <div className="flex flex-wrap -mx-3">
            {templates.map((template, index) => (
              <div className="w-full md:w-1/2 lg:w-1/3 px-3 mb-6">
                <HoverVideoPlayer
                  key={index}
                  sizingMode="overlay"
                  unloadVideoOnPaused={true}
                  restartOnPaused={true}
                  preload="none"
                  videoSrc={template.videoPreview}
                  pausedOverlay={
                  <picture  className="w-full rounded cursor-pointer">
                    <source srcSet={template.poster.localFile.childImageSharp.gatsbyImageData.images.sources[0].srcSet} type="image/webp" alt={template.title} /> 
                    <source srcSet={template.poster.localFile.childImageSharp.gatsbyImageData.images.fallback.srcSet} type="image/jpeg" alt={template.title} /> 
                    <img src={template.poster.localFile.childImageSharp.gatsbyImageData.images.fallback.src} alt={template.title} />
                  </picture>
                }
                  className="videoPlayer cursor-pointer rounded m-0.5"
                />
              </div>
            ))}
          </div>

          <div className="hidden justify-end">
            <button className="inline-flex text-white bg-indigo-700 h-12 px-5 focus:outline-none hover:bg-indigo-600 rounded items-center border border-transparent">View All Templates<FiArrowRight className="ml-1" /></button>
          </div>

        </div>
      </section>
      
      </Layout>
    </>
  );
}

export default StockVideoDevicePage;
